import { useState, useEffect } from "react";
import axios from "axios";
import { GraphTyper } from "../../componentes/typification/graphics/GraphTyper";
import elementos from "../../helpers/tipificacion";
import Layout from "../../componentes/ui/layout";
import Header from "../../general/Header";
import { url } from "../../data/url";

export default function GraphT() {
  const [dataTyperV, setDataTypV] = useState([]);
  const [dataTyperP, setDataTypP] = useState([]);
  const [dataTyperR, setDataTypR] = useState([]);
  const [dataTypernoA, setDataTypnoA] = useState([]);
  const [activeButton, setActiveButton] = useState("diario");
  const [hasLoadedData, setHasLoadedData] = useState(false); // cargue una vez ussefect

  const fetchDataByTyperV = async (typerV, followT, typerDate) => {
    const tablaSearch = "tab_base_principal";
    const tablaSearch2 = "typer";
    let campo = "Typer";
    let campo_date = "Date_typer";

    try {
      let fieldFollow =
        typerV === "Seguimiento" ||
          typerV === "Venta" ||
          typerV === "No venta rechazo" ||
          typerV === "No venta no apto"
          ? "Contact_typer"
          : "0";

      const requests = followT.map(async (followParam) => {
        const res = await axios.get(
          `${url}/graphics/${typerDate}/${tablaSearch}/${campo_date}/${campo}/${typerV}/${fieldFollow}/${followParam}`
        );
        const res2 = await axios.get(
          `${url}/graphics/${typerDate}/${tablaSearch2}/${campo_date}/${campo}/${typerV}/${fieldFollow}/${followParam}`
        );
        let dataUni = [...(res.data.rows || []), ...(res2.data.rows || [])];

        return dataUni;
      });

      const responses = await Promise.all(requests);
      return responses.flat();
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const loadDataDate = async (typerDate) => {
    setDataTypV(await fetchDataByTyperV("Venta", ["Venta"], typerDate));
    setDataTypP(
      await fetchDataByTyperV("Seguimiento", elementos.seguimiento, typerDate)
    );
    setDataTypR(
      await fetchDataByTyperV(
        "No venta rechazo",
        elementos.no_venta_rec,
        typerDate
      )
    );
    setDataTypnoA(
      await fetchDataByTyperV(
        "No venta no apto",
        elementos.no_venta_apto,
        typerDate
      )
    );
  };

  useEffect(() => {
    const loadData = async () => {
      loadDataDate("dayGraphics");
    };

    if (!hasLoadedData) {
      loadData();
      setHasLoadedData(true);
    }

    const interval = setInterval(() => {
      loadDataDate(
        activeButton === "diario"
          ? "dayGraphics"
          : activeButton === "semanal"
            ? "weekGraphics"
            : "monthGraphics"
      );
    }, 3000);

    return () => clearInterval(interval);
  }, [activeButton]);

  return (
    <>
      <div className="d-flex">
        <div className="container-tipif">
          <Header />
        </div>
        <div style={{ width: "87%" }}>
          <Layout titulo="Gráficas" />
          <div className="mx-5">
            <h1 className="title text-center fs-1 fw-normal">Gráficas</h1>
            <div className="col-4 mb-3 w-100">
              <div className="bg-light rounded shadow p-2 mb-3">
                <nav className="nav nav-pills nav-fill">
                  <a
                    className={
                      activeButton === "diario"
                        ? "nav-link buttons-primary pe-auto"
                        : "nav-link text-dark"
                    }
                    onClick={() => {
                      loadDataDate("dayGraphics");
                      setActiveButton("diario");
                    }}
                  >
                    Diario
                  </a>
                  <a
                    className={
                      activeButton === "semanal"
                        ? "nav-link buttons-primary"
                        : "nav-link text-dark"
                    }
                    onClick={() => {
                      loadDataDate("weekGraphics");
                      setActiveButton("semanal");
                    }}
                  >
                    Semanal
                  </a>
                  <a
                    className={
                      activeButton === "mensual"
                        ? "nav-link buttons-primary"
                        : "nav-link text-dark"
                    }
                    onClick={() => {
                      loadDataDate("monthGraphics");
                      setActiveButton("mensual");
                    }}
                  >
                    Mensual
                  </a>
                </nav>
              </div>
            </div>
            <GraphTyper
              dataTyperV={dataTyperV}
              dataTyperP={dataTyperP}
              dataTyperR={dataTyperR}
              dataTypernoA={dataTypernoA}
            />
          </div>
          <div className="mx-5">
            <h1 className="title text-center fs-1 fw-normal">
              Reporte de Gestión
            </h1>
            <div className="bg-light rounded shadow p-4 mb-5">
              <iframe
                title="Informe Majority"
                width="100%"
                height="836"
                src="https://app.powerbi.com/view?r=eyJrIjoiYjk1MDUzMjQtYzgwMy00NDcwLTk3OWEtYWY4NTljZDcyNDQ2IiwidCI6IjU5NzBmMGVjLTEyMzAtNGMzMi04ZTIyLTY0YTRhZTFlOGE2MiIsImMiOjR9"
                frameborder="0"
                allowFullScreen="true"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
