import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Navbar } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import { dateCurrent } from "../../utils/DateNow";
import Cookies from "universal-cookie";
import axios from "axios";
import { url } from "../../data/url";
const cookie = new Cookies();


export default function Layout({ titulo }) {
  const [user] = useState(cookie.get("user"));

  let logoutTimer;

  function resetLogoutTimer() {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      cerrarSesion(); // Llama a la función logout cuando el temporizador llega a cero
    }, 2400000); // 60 minutos en milisegundos
  }
  // Detectar actividad del usuario
  window.addEventListener('mousemove', activityDetected);
  window.addEventListener('keydown', activityDetected);

  function activityDetected() {
    resetLogoutTimer(); // Reinicia el temporizador cada vez que se detecta actividad
  }
  const logeoLogout = async () => {
    const { fechaA, horaA } = dateCurrent();

    await axios.put(
      `${url}/updateLogout/logeo/id_user/${user.UserA}/fecha/${fechaA}`,
      {
        hora_finish: horaA,
        estado_conexion: 0,
      }
    );
  }
  const cerrarSesion = () => {

    cookie.remove("user", { path: "/" });
    window.location.href = "./";
    logeoLogout();
  };

  if (!cookie.get("user")) {
    window.location.href = "./";
  }

  document.addEventListener('DOMContentLoaded', resetLogoutTimer);

  return (
    <>
      <div className="container-nav d-flex align-items-center justify-content-between px-2 py-2 shadow position-sticky fixed-top">
        <div>
          <Navbar.Brand href="/home" className="mx-3 fw-bold fs-4">
            <span> <img
              className="rounded-circle me-2"
              style={{ width: '30px' }}
              src={require("../../assets/logo.png")}
              alt="logo-majority"
            />MAJORITY</span>
          </Navbar.Brand>

        </div>
        <div className="d-flex align-items-center">
          <span className="fw-medium">{titulo} | </span>
          <Navbar
            className=""
            collapseOnSelect
            expand="lg"
            style={{ zIndex: 2, position: "relative" }}
          >
            <Container fluid className="d-flex">
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">

                <div>
                  <NavDropdown
                    title={
                      <div style={{display:"contents"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                        </svg>
                      </div>
                    }
                    id={`offcanvasNavbarDropdown-expand-false`}
                  >
                    <div className="text-center my-2">
                      <h5 className="font-semibold">{user.NameU}</h5>
                    </div>
                    <NavDropdown.Divider />

                    <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                    <NavDropdown.Item href="/" onClick={() => cerrarSesion()}>
                      Log out
                    </NavDropdown.Item>
                  </NavDropdown>
                </div>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
      <div></div>
    </>
  );
}
