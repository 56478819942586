import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { url } from "../../data/url";
import elements from "../../helpers/tipificacion";
import { dateCurrent } from "../../utils/DateNow";

const cookie = new Cookies();

export default function FormularioEstadoAgente({
  data,
  setData,
  setShowModalAgent,
  showModalAgent,
}) {
  const [dateForm] = useState(data);
  const [userId, setUserId] = useState("");
  const [tipif, setTipif] = useState([]);
  const [seguimiento, setSeguimiento] = useState("");
  const [campana, setCampana] = useState("");
  const [observation, setObservation] = useState("");

  const [ediciones_agente, setEdiciones_agente] = useState("");
  const [user] = useState(cookie.get("user"));


  const handleClose = () => setShowModalAgent(false);

  const submitForm = async (e) => {
    const { fechaA, horaA } = dateCurrent();

    try {
      e.preventDefault();
      const tabla = "typer";
      let campo = "id";

      let res = await axios.put(
        `${url}/updateLead/${tabla}/${campo}/${dateForm[0].id}`,
        {
          Typer: tipif,
          Contact_typer: tipif === "Venta" ? "Venta" : seguimiento,
          ediciones_agente: ediciones_agente + 1,
          fecha_edicion: fechaA,
          hora_edicion: horaA
        }
      );
      const tablaHistorico = "tab_base_historico_tipi";

      await axios.post(
        `${url}/createGeneral/${tablaHistorico}`,
        {
          User_Id: userId,
          Date_typer: fechaA,
          Hour_typer: horaA,
          Campana_typer: campana,
          Typer: tipif,
          Contact_typer: tipif === "Venta" ? "Venta" : seguimiento,
          Observation_typer: observation,
          Id_agent: user.UserA,
          Name_agent: user.NameU,
        }
      );
      Swal.fire(res.data.err ? res.data.err : res.data.msg);
      search();
      setShowModalAgent(false);
    } catch (error) {
      console.log(error);
    }
  };
  const search = async () => {
    const tablaSearch = "typer";
    let campo = "id";
    try {
      let res = await axios.get(
        `${url}/search/${tablaSearch}/${campo}/${dateForm[0].id}`
      );
      if (res.data.length === 0) {
        setData([]);
      } else {
        setData(res.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewData = (p) => {
    setUserId(p.User_Id);
    setCampana(p.Campana_typer);
    setObservation(p.Observation_typer);
    setSeguimiento(p.Typer);
    setEdiciones_agente(p.ediciones_agente);
  };
  const clear = () => {
    setUserId("");
    setCampana("");
    setObservation("");
    setSeguimiento("");
    setEdiciones_agente("");
  };
  useEffect(() => {
    dateForm.length > 0 ? viewData(dateForm[0]) : clear();
  }, [dateForm]);

  return (
    <>
      <Modal className="border-0" show={showModalAgent} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar estado tipificacion </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={submitForm}>
            <Row>
              <Col xs={3}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Id</Form.Label>
                  <Form.Control
                    type="text"
                    value={dateForm[0].id}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>

              <Col xs={5}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Tipificación</Form.Label>
                  <Form.Select
                    className="select select-bordered w-full"
                    value={tipif ? tipif : null}
                    onChange={(e) => {
                      setTipif(
                        elements.tipificacion.filter((v) => v.resp == e.target.value)
                      );
                      setTipif(e.target.value);
                    }}
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    required
                  >
                    <option value="">Seleccione</option>
                    {[...new Set(elements.tipificacion.map((item) => item))].map(
                      (item, index) => (
                        <>
                          {item != "Venta" ? (
                            <option key={index} value={item}>
                              {item}
                            </option>

                          ) : <></>}
                        </>
                      )
                    )}
                  </Form.Select>
                </Form.Group>
              </Col>
              {tipif === "No venta rechazo" ? (
                <Col xs={4}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>No venta rechazo</Form.Label>
                    <Form.Select
                      className="select select-bordered w-full"
                      value={seguimiento}
                      onChange={(e) => {
                        setSeguimiento(e.target.value);
                      }}
                      required
                    >
                      <option value="">Seleccione</option>
                      {elements.no_venta_rec.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              ) : tipif === "No venta no apto" ? (
                <>
                  <Col xs={4}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>No venta no apto</Form.Label>
                      <Form.Select
                        className="select select-bordered w-full"
                        value={seguimiento}
                        onChange={(e) => {
                          setSeguimiento(e.target.value);
                        }}
                        required
                      >
                        <option value="">Seleccione</option>
                        {elements.no_venta_apto.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </>
              ) : tipif === "Seguimiento" ? (
                <Col xs={4}>
                  <label className="form-label text-sm">Seguimiento</label>
                  <Form.Select
                    className="select select-bordered w-full"
                    value={seguimiento}
                    onChange={(e) => {
                      setSeguimiento(e.target.value);
                    }}
                    required
                  >
                    <option selected value="">
                      Seleccione
                    </option>
                    {elements.seguimiento.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </Form.Select>
                </Col>

              ) : (
                <></>
              )}
              <div className="text-center mt-4">
                <Button type="submit" className="px-4 btn buttons-primary">
                  Actualizar Datos
                </Button>
              </div>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
