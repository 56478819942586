import React from "react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <div className="container" style={{ zIndex: 2, position: "relative" }}>
        <div className="vh-100 py-4">
          <div className="h-75 d-flex justify-content-center">
            <img
              src={require("../assets/ERROR.png")}
              alt="Logo Asiste"
              className="h-100 position-relative"
            />
          </div>
          <div className="h-25 d-flex justify-content-center align-items-center">
            <Link to="/" className="">
              <button className="btn buttons-primary btn-lg">
                Iniciar sesión
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
