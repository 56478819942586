import { lazy, Suspense } from "react";
import Layout from "../../componentes/ui/layout";
import { FilterGeneral } from "../../componentes/componentsGeneral/FilterGeneral";
import Header from "../../general/Header";
import Loader from "../../componentes/ui/Loader";
import { useApiGetId } from "../../componentes/hooks/useApiGetId";
const TablePostsale = lazy(() =>
  import("../../componentes/Postsale/TablePostsale")
);


export default function Search() {
  const { data, setData, table, setTable, searching } = useApiGetId();

  const tabla = "postsale";
  const nameUno = "date_r";
  const nameDos = "Campana_typer";
  const nameCinco = "id_agent";
  const tablaSearch = "typer";
  const name_userId = "id_custom";
  const phoneNumber = "phone_Number";


  return (
    <>
      <div className="d-flex" style={{ width: "100vw" }}>
        <div className="container-tipif">
          <Header></Header>
        </div>

        <div style={{ width: "87%" }}>
          <Layout titulo="Posventa" />
          <div className="mx-5 ">
            <h1 className="title text-center fs-1 fw-normal">Consultas</h1>
            <FilterGeneral
              data={data}
              searching={searching}
              setDate={setData}
              setTable={setTable}
              tabla={tabla}
              name_userId={name_userId}
              phoneNumber={phoneNumber}
              nameUno={nameUno}
              nameDos={nameDos}
              nameCinco={nameCinco}
              tablaSearch={tablaSearch}
            />
            <br />
            {table && (
              <Suspense fallback={<Loader />}>
                <TablePostsale data={data} setData={setData} />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
