import { Container, Row, Col } from "react-bootstrap";

import Header from "../general/Header";
import Layout from "../componentes/ui/layout";

export default function Home() {
  return (
    <>
      <div className="d-flex">
        <div className="container-tipif">
          <Header></Header>
        </div>
        <div style={{ width: "87%" }}> {/*width: 89% */}
          <Layout titulo="Home" />
          <div className="d-flex">
            <Container className="container-home mb-3">
              <Row>
                <Col xs={6} md={8}>
                  <div className="mb-5">
                    <div>
                      <img
                        className="mx-auto rounded-3 shadow img-fluid hover:scale-110"
                        src={require("../assets/home/PAUSAS.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={4}>
                  <div>
                    <img
                      className="mx-auto rounded-3 shadow  img-fluid"
                      src={require("../assets/home/YO-SOY.jpg")}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div >
    </>
  );
}
