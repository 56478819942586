import { useState } from "react";
import React from "react";
import Cookies from "universal-cookie";
import { exportExcelFile } from "../../utils/StructureFileExcel";
const cookie = new Cookies();

export const ApiRequests = ({
  data,
  tabla,
  initialDate,
  searching,
  endDate,
}) => {
  const [user] = useState(cookie.get("user"));

  return (
    <>
      {user.role_id === 2006 && data.length > 0 && (
        <button
          className="btn btn-success me-4"
          onClick={() => exportExcelFile(tabla, data, initialDate, endDate)}
        >
          <i className="bi bi-file-earmark-excel"></i>
        </button>
      )}
      <button
        type="button"
        className="btn buttons-primary px-5 w-75"
        onClick={searching}
      >
        <i className="bi bi-search me-2"></i>Search
      </button>
    </>
  );
};
