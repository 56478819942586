import { lazy, Suspense, useState } from "react";
import Layout from "../../componentes/ui/layout";
import { FilterGeneral } from "../../componentes/componentsGeneral/FilterGeneral";
import Header from "../../general/Header";
import Loader from "../../componentes/ui/Loader";
import FormularioEstado from "../../componentes/typification/FormularioEstado";
import FormularioEstadoAgente from "../../componentes/typification/FormularioEstadoAgente";
import { useApiGetAll } from "../../componentes/hooks/useApiGet";
const TableTyper = lazy(() =>
  import("../../componentes/typification/TableTyper")
);

export default function Search() {
  const { data, setData, table, setTable, searching } = useApiGetAll();
  const [showModalT, setShowModalT] = useState(false);
  const [showModalAgent, setShowModalAgent] = useState(false);
  const tabla = "tab_base_principal";
  const nameUno = "Date_typer";
  const nameDos = "Campana_typer";
  const nameCinco = "id_agent";
  const name_userId = "User_Id";
  const phoneNumber = "Phone_Number";
  const tablaSearch = "tab_base_principal";


  return (
    <>
      <div className="d-flex">
        <div className="container-tipif">
          <Header></Header>
        </div>

        <div style={{ width: "87%" }}>
          <Layout titulo="Tipificación" />
          <div className="mx-5">
            <h1 className="title text-center fs-1 fw-normal">Consultas</h1>
            <FilterGeneral
              setDate={setData}
              data={data}
              searching={searching}
              setTable={setTable}
              tabla={tabla}
              nameUno={nameUno}
              nameDos={nameDos}
              nameCinco={nameCinco}
              phoneNumber={phoneNumber}
              name_userId={name_userId}
              tablaSearch={tablaSearch}
            />
            <br />
            {table && (
              <Suspense fallback={<Loader />}>
                <TableTyper
                  data={data}
                  setData={setData}
                  setShowModal={setShowModalT}
                  setShowModalAgent={setShowModalAgent}
                />
              </Suspense>
            )}
            {showModalT && (
              <FormularioEstado
                data={data}
                setData={setData}
                setShowModal={setShowModalT}
                showModal={showModalT}
              />
            )}
            {showModalAgent && (
              <FormularioEstadoAgente
                data={data}
                setData={setData}
                setShowModalAgent={setShowModalAgent}
                showModalAgent={showModalAgent}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
