import React from "react";
import { ApiRequests } from "../services/ApiRequests";
import { useState } from "react";
import Cookies from "universal-cookie";
import { Col, Form, Row } from "react-bootstrap";
import { dateCurrent } from "../../utils/DateNow";

export const FilterGeneral = ({
  data,
  tabla,
  nameUno, //filtroFch
  nameDos,
  searching,
  nameCinco,
  phoneNumber,
  name_userId,
}) => {
  const cookie = new Cookies();
  const { fechaA } = dateCurrent();

  const [user] = useState(cookie.get("user"));
  const [initialDate, setInitialDate] = useState(fechaA);
  const [endDate, setendDate] = useState(fechaA);
  const [campana, setCampana] = useState("");
  const [num_doc, setNumDoc] = useState("");


  const searchController = async () => {
    let nameVUser = user.role_id === 2006 ? 0 : nameCinco;
    let UserDocume = user.role_id === 2006 ? 0 : user.UserA;

    try {
      searching(
        tabla,
        nameUno,
        initialDate,
        endDate,
        nameDos,
        campana,
        nameVUser,
        UserDocume,
        num_doc,
        phoneNumber,
        name_userId
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="rounded shadow p-4 bg-light">
        <Row className="justify-content-end align-items-end">
          <Col sm={2}>
            <Form.Label className="form-label text-sm">
              Fecha Inicial
            </Form.Label>
            <Form.Control
              type="date"
              className="input-bordered"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </Col>
          <Col sm={2}>
            <Form.Label className="form-label text-sm">Fecha Final</Form.Label>
            <Form.Control
              type="date"
              className="input-bordered"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col sm={2}>
            <Form.Label className="form-label text-sm">Campaña</Form.Label>
            <select
              className="form-select"
              value={campana ? campana : null}
              onChange={(e) => setCampana(e.target.value)}
              id="floatingSelect"
              aria-label="Floating label select example"
              required
            >
              <option selected value="">
                Seleccione
              </option>

              <option value="'Inbound'">Inbound</option>
              <option value="'Outbound'">Outbound</option>
              <option value="'Whatsapp'">Whatsapp</option>
              <option value="'Superdinero'">Superdinero</option>
              <option value="'Customer service'">Customer service</option>
              <option value="'Referido'">Referido</option>
              <option value="'Wcb'">Wcb</option>
              <option value="'Inbound','Outbound','Whatsapp','Superdinero','Customer service','Referido','Wcb','','No tiene campaña'">
                Todas
              </option>
            </select>
          </Col>
          <Col sm={3}>
            <Form.Label className="form-label text-sm">
              Buscar User ID o Teléfono
            </Form.Label>
            <Form.Control
              type="text"
              className="input-bordered"
              placeholder="User Id o Teléfono"
              value={num_doc}
              onChange={(e) => setNumDoc(e.target.value)}
            />
          </Col>
          <Col sm={3}>
            <ApiRequests
              searching={searchController}
              data={data}
              tabla={tabla}
              initialDate={initialDate}
              endDate={endDate}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
