import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { url } from "../../data/url";

export default function FormularioEstado({
  data,
  setData,
  setShowModal,
  showModal,
}) {
  const [dateForm] = useState(data);

  const [seguimiento, setSeguimiento] = useState("");
  const [idData, setIdData] = useState("");
  const handleClose = () => setShowModal(false);


  const submitForm = async (e) => {
    try {
      e.preventDefault();
      const tabla = "tab_base_principal";
      let campo = "User_Id";
      const tabla2 = "typer";
      let campo2 = "id";

      let res = await axios.put(
        `${url}/updateLead/${tabla}/${campo}/${dateForm[0].User_Id}`,
        {
          Typer: seguimiento,
          Contact_typer: seguimiento,
        }
      );
    await axios.put(
        `${url}/updateLead/${tabla2}/${campo2}/${dateForm[0].id}`,
        {
          Typer: seguimiento,
          Contact_typer: seguimiento,
        }
      );
    Swal.fire(res.data.err ? res.data.err : res.data.msg);
      search();
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const search = async () => {
    const tablaSearch = "tab_base_principal";
    let campo = "User_Id";
    const tablaSearch2 = "typer";
    let campo2 = "id";

    try {
      let res = await axios.get(
        `${url}/search/${tablaSearch}/${campo}/${dateForm[0].User_Id}`
      );
      let res2 = await axios.get(
        `${url}/search/${tablaSearch2}/${campo2}/${dateForm[0].id}`
      );
      if (res.data.length === 0 || res2.data.length === 0) {
        setData([]);
      } else {
        setData(res.data.rows || res2.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const viewData = (p) => {
    setIdData(p.id === undefined ? p.User_Id : p.id);
    setSeguimiento(p.Typer);
  };
  const clear = () => {
    setIdData("");
    setSeguimiento("");
  };
  
  useEffect(() => {
    dateForm.length > 0 ? viewData(dateForm[0]) : clear();
  }, [dateForm]);

  return (
    <>
      <Modal className="border-0" show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar estado</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={submitForm}>
            <Row>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Id</Form.Label>
                  <Form.Control
                    type="text"
                    value={idData}
                    readOnly
                    disabled
                  />
                </Form.Group>
              </Col>

              <Col xs={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Tipificación</Form.Label>
                  <Form.Select
                    className="select select-bordered w-full"
                    value={seguimiento}
                    onChange={(e) => {
                      setSeguimiento(e.target.value);
                    }}
                    required
                  >
                    <option selected value="">
                      Seleccione
                    </option>

                    <option value={"Venta"}>Venta</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              {seguimiento === "Venta" ? (
                <Col xs={3}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Seguimiento</Form.Label>

                    <Form.Control
                      type="text"
                      value={"Venta"}
                      readOnly
                      disabled
                    />
                  </Form.Group>
                </Col>
              ) : (
                <></>
              )}

              <div className="text-center mt-4">
                <Button type="submit" className="px-4 btn buttons-primary">
                  Actualizar Datos
                </Button>
              </div>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
