import { lazy, Suspense, useState } from "react";

import Header from "../../general/Header";
import Layout from "../../componentes/ui/layout";
import Loader from "../../utils/Loader";
import { FilterLeads } from "../../componentes/componentsGeneral/FilterLeads";

const TablaLeads = lazy(() =>
  import("../../componentes/leads/consultaPrincipal")
);
export default function Lead() {
  const tabla = "tab_base_principal ";
  const nameUno = "User_Signup_Date";
  const tablaSearch = "tab_base_principal";
  const documento = "User_id";

  const [table, setTable] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className="d-flex" style={{ width: "100vw" }}>
        <div className="container-tipif ">
          <Header></Header>
        </div>
        <div style={{ width: "87%" }}>
          <Layout titulo="Leads" />
          <div className="mx-5 ">
            <h1 className="mt-5 title text-center fs-1 fw-normal">
              Lead inquiry
            </h1>

            <FilterLeads
              data={data}
              setData={setData}
              setTable={setTable}
              setIsLoading={setIsLoading}
              tabla={tabla}
              nameUno={nameUno}
              tablaSearch={tablaSearch}
              documento={documento}
            />
            <br />
            {table && (
              <Suspense fallback={<Loader />}>
                <TablaLeads data={data} setData={setData} />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
