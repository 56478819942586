import axios from 'axios';
import { useState } from 'react';
import Swal from 'sweetalert2';
import { url } from '../../data/url';

export function useApiGetAll() {
    const [data, setData] = useState([]);
    const [table, setTable] = useState(false);
    const searching = async (tabla, nameUno, initialDate, endDate, nameDos, campana, nameVUser, UserDocume, num_doc, phoneNumber, name_userId) => {
        const table2 = "typer";

        try {
            if (num_doc !== "") {
                const searchRoutes = await axios.get(
                    `${url}/searchTyper/${phoneNumber}/${name_userId}/${num_doc}`,
                );

                if (searchRoutes.data.rows !== undefined && searchRoutes.data.rows.length > 0) {
                    setData(searchRoutes.data.rows);
                    setTable(true);
                } else {
                    setData([]);
                    setTable(false);
                }

                Swal.fire(searchRoutes.data.err ? searchRoutes.data.err : searchRoutes.data.msg);
            } else {
                const [res, res2] = await Promise.all([
                    axios.get(
                        `${url}/searchAll/${tabla}/${nameUno}/${initialDate}/${endDate}/${nameDos}/${campana}/${nameVUser}/${UserDocume}`
                    ),
                    axios.get(
                        `${url}/searchAll/${table2}/${nameUno}/${initialDate}/${endDate}/${nameDos}/${campana}/${nameVUser}/${UserDocume}`
                    ),
                ]);

                let dataTyper = [];
                if (res.data.rows) dataTyper = [...dataTyper, ...res.data.rows];
                if (res2.data.rows) dataTyper = [...dataTyper, ...res2.data.rows];

                setData(dataTyper);
                setTable(dataTyper.length > 0);

                Swal.fire(
                    dataTyper !== undefined
                        ? {
                            position: "top-end",
                            icon: "info",
                            toast: true,
                            title: dataTyper.length + " resultados",
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: 3000,
                        }
                        : {
                            position: "top-end",
                            icon: "error",
                            toast: true,
                            title:
                                "Los datos no se encuentran registrados en el sistema",
                            showConfirmButton: false,
                            timerProgressBar: true,
                            timer: 3000,
                        }
                );
            }

        } catch (error) {
            console.log("Ocurrio un error: " + error);
            Swal.fire("Error");
        }
    };

    return { data, setData, table, setTable, searching };

}