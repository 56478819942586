import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { url } from "../../data/url";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import elements from "../../helpers/tipificacion";
import Loader from "../ui/Loader";
import { dateCurrent } from "../../utils/DateNow";
const cookie = new Cookies();

export default function FormularioTipificacion({ id, data, setData }) {
  const [user] = useState(cookie.get("user"));
  const [celular_cliente, setCelular_cliente] = useState("");
  const [nombres_cliente, setNombres_cliente] = useState("");
  const [apellido_cliente, setApellido_cliente] = useState("");
  const [campana, setCampana] = useState("");
  const [tipif, setTipif] = useState([]);
  const [seguimiento, setSeguimiento] = useState("");
  const [observation, setObservation] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fechaA, horaA } = dateCurrent();

    try {
      setIsLoading(true);
      const tabla = "tab_base_principal";
      const campo = "User_Id";

      let res = await axios.put(`${url}/updateLead/${tabla}/${campo}/${id}`, {
        First_Name: nombres_cliente,
        Family_Name: apellido_cliente,
        Date_typer: fechaA,
        Hour_typer: horaA,
        Campana_typer: campana,
        Typer: tipif,
        Contact_typer: tipif === "Venta" ? "Venta" : seguimiento,
        Observation_typer: observation,
        Id_agent: user.UserA,
        Name_agent: user.NameU,

      });
      if (res.data !== undefined) {
        setIsLoading(false);
        Swal.fire(res.data.err ? res.data.err : res.data.msg);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        navigate("/typification/consultas");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validate = (parameter) => {
    if (parameter) {
      search();
    }
  };

  useEffect(() => {
    validate(id);
  }, []);

  const search = async () => {
    const tablaSearch = "tab_base_principal";
    let campo = "User_Id";
    try {
      let res = await axios.get(`${url}/search/${tablaSearch}/${campo}/${id}`);
      if (res.data.rows.length === 0) {
        setData([]);
      } else {
        setData(res.data.rows);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Renderiza los datos en el formulario
  useEffect(() => {
    data.length > 0 ? dataTyper(data[0]) : clear();
  }, [data]);

  const dataTyper = (f) => {
    setCelular_cliente(f.Phone_Number);
    setNombres_cliente(f.First_Name);
    setApellido_cliente(f.Family_Name);
  };

  const clear = () => {
    setCelular_cliente("");
    setNombres_cliente("");
    setApellido_cliente("");
  };

  return (
    <>
      <form
        className="text-dark p-4 bg-light rounded shadow"
        onSubmit={handleSubmit}
      >
        <div className="row">
          {id && (
            <div className="col-4 mt-3">
              <label className="form-label text-sm">User ID</label>
              <input
                type="text"
                className="form-control input-bordered w-full"
                value={id}
                readOnly
                disabled
              />
            </div>
          )}
          <div className="col-4 mt-3">
            <label className="form-label text-sm">Campaña tipificación</label>
            <select
              className="form-select"
              value={campana != null || campana != "" ? campana : "No tiene campaña"}
              onChange={(e) => setCampana(e.target.value || "")}
              required
            >
              <option value="">Seleccione</option>
              {elements.campana.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-4 mt-3">
            <label className="form-label text-sm"># Celular del cliente</label>
            <input
              type="text"
              className="form-control input-bordered w-full"
              value={celular_cliente}
              onChange={(e) => setCelular_cliente(e.target.value)}
              readOnly
              required
            />
          </div>
          <div className="col-4 mt-3">
            <label className="form-label text-sm">Nombres del cliente</label>
            <input
              type="text"
              className="form-control input-bordered w-full"
              value={nombres_cliente}
              onChange={(e) => setNombres_cliente(e.target.value)}
            />
          </div>

          <div className="col-4 mt-3">
            <label className="form-label text-sm">Apellidos del cliente</label>
            <input
              type="text"
              className="form-control input-bordered w-full"
              value={apellido_cliente}
              onChange={(e) => setApellido_cliente(e.target.value)}
            ></input>
          </div>

          <div className="col-4 mt-3">
            <label className="form-label text-sm">Tipificación</label>
            <select
              value={tipif ? tipif : null}
              className="form-select"
              onChange={(e) => {
                setTipif(
                  elements.tipificacion.filter((v) => v.resp == e.target.value)
                );
                setTipif(e.target.value);
              }}
              id="floatingSelect"
              aria-label="Floating label select example"
              required
            >
              <option selected value="">
                Seleccione
              </option>
              {[...new Set(elements.tipificacion.map((item) => item))].map(
                (item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                )
              )}
            </select>
          </div>
          {tipif === "Seguimiento" ? (
            <div className="col-4 mt-3">
              <label className="form-label text-sm">Seguimiento</label>
              <select
                className="form-select"
                value={seguimiento ? seguimiento : null}
                onChange={(e) => setSeguimiento(e.target.value)}
                id="floatingSelect"
                aria-label="Floating label select example"
                required
              >
                <option selected value="">
                  Seleccione
                </option>
                {elements.seguimiento.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : tipif === "No venta rechazo" ? (
            <div className="col-4 mt-3">
              <label className="form-label text-sm">No venta rechazo</label>
              <select
                className="form-select"
                value={seguimiento ? seguimiento : null}
                onChange={(e) => setSeguimiento(e.target.value)}
                id="floatingSelect"
                aria-label="Floating label select example"
                required
              >
                <option selected value="">
                  Seleccione
                </option>
                {elements.no_venta_rec.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : tipif === "No venta no apto" ? (
            <>
              <div className="col-4 mt-3">
                <label className="form-label text-sm">No venta no apto</label>
                <select
                  className="form-select"
                  value={seguimiento ? seguimiento : null}
                  onChange={(e) => setSeguimiento(e.target.value)}
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  required
                >
                  <option selected value="">
                    Seleccione
                  </option>
                  {elements.no_venta_apto.map((item, index) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="col-12 mt-3">
            <label className="form-label text-sm">Observación</label>
            <textarea
              rows="2"
              className="form-control resize-none"
              value={observation}
              onChange={(e) => setObservation(e.target.value)}
              required
            ></textarea>
          </div>
        </div>
        <div className="text-center mt-4">
          {isLoading ? <Loader title={"Ingresando datos,"} /> : ""}
          <button
            disabled={isLoading}
            type="submit"
            className="px-4 btn buttons-primary"
          >
            Registrar Datos
          </button>
        </div>
      </form>
    </>
  );
}
