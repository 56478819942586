import React from "react";
import { useState } from "react";
import Cookies from "universal-cookie";
import { Col, Form, Row } from "react-bootstrap";
import { ApiRequestsLeadsData } from "../services/ApiRequestsLeadsData";
import { dateCurrent } from "../../utils/DateNow";


export const FilterLeads = ({
  data,
  setData,
  setTable,
  setIsLoading,
  tabla,
  nameUno, //filtroFch
  tablaSearch,
  documento,
}) => {
  const cookie = new Cookies();

  const [user] = useState(cookie.get("user"));
  const { fechaA } = dateCurrent();

  const [initialDate, setInitialDate] = useState(fechaA);
  const [endDate, setendDate] = useState(fechaA);
  const [num_doc, setNumDoc] = useState("");

  return (
    <>
      <div className="rounded shadow p-4 bg-light">
        <Row className="justify-content-end align-items-end">
          <Col sm={3}>
            <Form.Label className="form-label text-sm">
              Fecha Inicial
            </Form.Label>
            <Form.Control
              type="date"
              className="input-bordered"
              value={initialDate}
              onChange={(e) => setInitialDate(e.target.value)}
            />
          </Col>
          <Col sm={3}>
            <Form.Label className="form-label text-sm">Fecha Final</Form.Label>
            <Form.Control
              type="date"
              className="input-bordered"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </Col>
          <Col sm={3}>
            <Form.Label className="form-label text-sm">
              Buscar User ID
            </Form.Label>
            <Form.Control
              type="text"
              className="input-bordered"
              placeholder="User Id"
              value={num_doc}
              onChange={(e) => setNumDoc(e.target.value)}
            />
          </Col>
          <Col sm={3}>
            <ApiRequestsLeadsData
              data={data}
              setData={setData}
              setTable={setTable}
              setIsLoading={setIsLoading}
              tabla={tabla}
              nameUno={nameUno}
              num_doc={num_doc}
              initialDate={initialDate}
              endDate={endDate}
              idUser={user.UserA}
              tablaSearch={tablaSearch}
              documento={documento}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
