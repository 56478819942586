import { useState } from "react";
import Cookies from "universal-cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/js/dist/collapse";
import logoAING from "../assets/home/LOGO-ING-B.png";
const cookie = new Cookies();

export default function Header() {
  const [user] = useState(cookie.get("user"));

  if (!cookie.get("user")) {
    window.location.href = "./";
  }

  return (
    <>
      <div className="container-fluid sticky-top">
        <div className="row">
          <div className="bg-men bg-dark vh-100 py-2">
            <div className="text-center">
              <a
                className="text-decoration-none mx-1 d-flex align-items-center text-white d-none d-sm-inline"
                role="button"
                href="/home"
              >
                <span className="fs-5">
                  <img
                    className="w-50 "
                    src={logoAING}
                    alt="logo-asiste"
                  />
                </span>
              </a>
              <hr className="text-white mt-4 d-none d-sm-block"></hr>
              <ul
                className="nav nav-pills flex-column mt-2 mt-sm-0"
                id="parentM"
              >
                <li className="nav-item my-1 py-2 py-sm-0">
                  <a
                    href="/home"
                    className="nav-link text-white text-center text-sm-start"
                    aria-current="page"
                  >
                    <i className="bi bi-house d-none d-xl-inline"></i>
                    <span className="ms-1 d-none d-sm-inline">Home</span>
                  </a>
                </li>
                {user.role_id === 2000 ? (
                  <>
                    <li className="nav-item my-1 py-2 py-sm-0">
                      <a
                        href="#submenula"
                        className="nav-link text-white text-center text-sm-start"
                        data-bs-toggle="collapse"
                        aria-current="page"
                      >
                        <i className="bi bi-people-fill d-none d-xl-inline"></i>
                        <span className="ms-1 d-none d-sm-inline">Leads</span>
                        <i className="bi bi-arrow-down-short ms-0 ms-sm-2"></i>
                      </a>
                      <ul
                        className="nav collapse ms-1 flex-column"
                        id="submenula"
                        data-bs-parent="#parentM"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            href="/typification/leads"
                          >
                            <span className="d-none d-sm-inline">Register</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item my-1 py-2 py-sm-0">
                      <a
                        href="#submenu"
                        className="nav-link text-white text-center text-sm-start"
                        data-bs-toggle="collapse"
                        aria-current="page"
                      >
                        <i className="bi bi-grid d-none d-xl-inline"></i>
                        <span className="ms-1 d-none d-sm-inline">
                          Typification
                        </span>
                        <i className="bi bi-arrow-down-short ms-0 ms-sm-2"></i>
                      </a>
                      <ul
                        className="nav collapse ms-1 flex-column"
                        id="submenu"
                        data-bs-parent="#parentM"
                      >
                        <li className="nav-item ">
                          <a
                            className="nav-link text-white"
                            href="/typification/registrarExt"
                            aria-current="page"
                          >
                            <span className="d-none d-sm-inline">Registrar externo</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            href="/typification/consultas"
                          >
                            <span className="d-none d-sm-inline">Search</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item my-1 py-2 py-sm-0">
                      <a
                        href="#submenu5"
                        className="nav-link text-white text-center text-sm-start"
                        data-bs-toggle="collapse"
                        aria-current="page"
                      >
                        <i className="bi bi-grid d-none d-xl-inline"></i>
                        <span className="ms-1 d-none d-sm-inline">
                          History Typer
                        </span>
                        <i className="bi bi-arrow-down-short ms-0 ms-sm-2"></i>
                      </a>
                      <ul
                        className="nav collapse ms-1 flex-column"
                        id="submenu5"
                        data-bs-parent="#parentS"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            href="/historytyper/consultas"
                          >
                            <span className="d-none d-sm-inline">Search</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item my-1 py-2 py-sm-0">
                      <a
                        href="#submenu2"
                        className="nav-link text-white text-center text-sm-start"
                        data-bs-toggle="collapse"
                        aria-current="page"
                      >
                        <i className="bi bi-bag-check d-none d-xl-inline"></i>
                        <span className="ms-1 d-none d-sm-inline">
                          PostSale
                        </span>
                        <i className="bi bi-arrow-down-short ms-0 ms-sm-2"></i>
                      </a>
                      <ul
                        className="nav collapse ms-1 flex-column"
                        id="submenu2"
                        data-bs-parent="#parentS"
                      >
                        <li className="nav-item ">
                          <a
                            className="nav-link text-white"
                            href="/postsale/registrar"
                            aria-current="page"
                          >
                            <span className="d-none d-sm-inline">Register</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            href="/postsale/consultas"
                          >
                            <span className="d-none d-sm-inline">Search</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </>
                ) : user.role_id === 2006 ? (
                  <>

                    <li className="nav-item my-1 py-2 py-sm-0">
                      <a
                        href="#submenu2"
                        className="nav-link text-white text-center text-sm-start"
                        data-bs-toggle="collapse"
                        aria-current="page"
                      >
                        <i className="bi bi-grid d-none d-xl-inline"></i>
                        <span className="ms-1 d-none d-sm-inline">
                          Typification
                        </span>
                        <i className="bi bi-arrow-down-short ms-0 ms-sm-2"></i>
                      </a>
                      <ul
                        className="nav collapse ms-1 flex-column"
                        id="submenu2"
                        data-bs-parent="#parentS"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            href="/typification/consultas"
                          >
                            <span className="d-none d-sm-inline">Search</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item my-1 py-2 py-sm-0">
                      <a
                        href="#submenu5"
                        className="nav-link text-white text-center text-sm-start"
                        data-bs-toggle="collapse"
                        aria-current="page"
                      >
                        <i className="bi bi-grid d-none d-xl-inline"></i>
                        <span className="ms-1 d-none d-sm-inline">
                          History Typer
                        </span>
                        <i className="bi bi-arrow-down-short ms-0 ms-sm-2"></i>
                      </a>
                      <ul
                        className="nav collapse ms-1 flex-column"
                        id="submenu5"
                        data-bs-parent="#parentS"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            href="/historytyper/consultas"
                          >
                            <span className="d-none d-sm-inline">Search</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item my-1 py-2 py-sm-0">
                      <a
                        href="#submenu3"
                        className="nav-link text-white text-center text-sm-start"
                        data-bs-toggle="collapse"
                        aria-current="page"
                      >
                        <i className="bi bi-bag-check d-none d-xl-inline"></i>
                        <span className="ms-1 d-none d-sm-inline">
                          PosSale
                        </span>
                        <i className="bi bi-arrow-down-short ms-0 ms-sm-2"></i>
                      </a>
                      <ul
                        className="nav collapse ms-1 flex-column"
                        id="submenu3"
                        data-bs-parent="#parentS"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            href="/postsale/consultas"
                          >
                            <span className="d-none d-sm-inline">Search</span>
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item my-1 py-2 py-sm-0">
                      <a
                        href="#submenu4"
                        className="nav-link text-white text-center text-sm-start"
                        data-bs-toggle="collapse"
                        aria-current="page"
                      >
                        <i className="bi bi-bar-chart d-none d-xl-inline"></i>
                        <span className="ms-1 d-none d-sm-inline">
                          Graphics
                        </span>
                        <i className="bi bi-arrow-down-short ms-0 ms-sm-2"></i>
                      </a>
                      <ul
                        className="nav collapse ms-1 flex-column"
                        id="submenu4"
                        data-bs-parent="#parentS"
                      >
                        <li className="nav-item">
                          <a
                            className="nav-link text-white"
                            href="/typification/graphs"
                          >
                            <span className="d-none d-sm-inline">Search</span>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
