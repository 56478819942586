import { useState } from "react";
import axios from "axios";
import React from "react";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { url } from "../../data/url";
import { exportExcelFile } from "../../utils/StructureFileExcel";
const cookie = new Cookies();

export const ApiRequestsLeadsData = ({
  data,
  setData,
  setTable,
  tabla,
  nameUno, //filtroFch
  initialDate,
  endDate,
  setIsLoading,
  nameCinco,
  idUser,
  documento,
  num_doc,
}) => {
  const [user] = useState(cookie.get("user"));


  const searchAll = async () => {
    try {
      if (num_doc !== "") {
        setIsLoading(true);

        let res;
        res = await axios.get(`${url}/search/${tabla}/${documento}/${num_doc}`);

        Swal.fire(res.data.err ? res.data.err : res.data.msg);

        if (res.data.rows === undefined) {
          setIsLoading(false);
          setData([]);
          setTable(false);
        } else {
          setIsLoading(false);
          setData(res.data.rows);
          setTable(true);
        }
      } else {
        setIsLoading(true);

        let res = await axios.get(`${url}/consultLead/${tabla}/${nameUno}/"${initialDate}"/"${endDate}"`);
        Swal.fire(res.data.err ? res.data.err : res.data.msg);
        if (res.data.rows === undefined) {
          setIsLoading(false);
          setTable(false)
          setData([]);
        } else {
          setIsLoading(true);
          setData(res.data.rows);
          setTable(true)
          filterTyper(res.data.rows);
          setIsLoading(false);
        }
      }
      
      
    } catch (error) {
      console.log(error);
    }
  };

  const filterTyper = (dataFil) => {
    const filterT = dataFil.filter(f => f.Typer == null);
    setData(filterT);
  }
  return (
    <>
    {user.role_id === 2006 && data.length > 0 && (
        <button
          className="btn btn-success me-4"
          onClick={() => exportExcelFile(tabla, data, initialDate, endDate)}
        >
          <i className="bi bi-file-earmark-excel"></i>
        </button>
      )}
      <button
        type="button"
        className="btn buttons-primary px-5 w-75"
        onClick={searchAll}
      >
        <i className="bi bi-search me-2"></i>Search
      </button>
    </>
  );
};
