import { lazy, Suspense } from "react";
import Layout from "../../componentes/ui/layout";
import { FilterHistory } from "../../componentes/componentsGeneral/FilterHistory";
import Header from "../../general/Header";
import Loader from "../../componentes/ui/Loader";
import { useApiGetId } from "../../componentes/hooks/useApiGetId";


const TableHistory = lazy(() =>
  import("../../componentes/historyTyper/TableHistoryTyper")
);

export default function Search() {
  const { data, setData, table, setTable, searching } = useApiGetId();
  const tabla = "tab_base_historico_tipi";
  const nameUno = "Date_typer";
  const nameCinco = "id_agent";
  const tablaSearch = "tab_base_historico_tipi";
  const name_userId = "User_Id";
  const phoneNumber = "Phone_Number";


  return (
    <>
      <div className="d-flex" style={{ width: "100vw" }}>
        <div className="container-tipif">
          <Header></Header>
        </div>

        <div style={{ width: "87%" }}>
          <Layout titulo="Historial Tipificación" />
          <div className="mx-5">
            <h1 className="title text-center fs-1 fw-normal">Consultar Histórico</h1>
            <FilterHistory
              data={data}
              searching={searching}
              setDate={setData}
              setTable={setTable}
              tabla={tabla}
              nameUno={nameUno}
              nameCinco={nameCinco}
              document={document}
              tablaSearch={tablaSearch}
              name_userId={name_userId}
              phoneNumber={phoneNumber}
            />
            <br />
            {table && (
              <Suspense fallback={<Loader />}>
                <TableHistory
                  data={data}
                  setData={setData}
                />
              </Suspense>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
