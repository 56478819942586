import React from "react";
import { ApiRequests } from "../services/ApiRequests";
import { useState } from "react";
import Cookies from "universal-cookie";
import { Col, Form, Row } from "react-bootstrap";

export const FilterHistory = ({
  data,
  tabla,
  searching,
  nameUno, //filtroFch
  nameDos,
  nameCinco,
  name_userId,
  phoneNumber,
}) => {
  const cookie = new Cookies();

  const [user] = useState(cookie.get("user"));
  const [num_doc, setNumDoc] = useState("");




  const searchController = async () => {
    let nameVUser = user.role_id === 2006 ? 0 : nameCinco;
    let UserDocume = user.role_id === 2006 ? 0 : user.UserA;

    try {
      searching(
        tabla,
        nameUno,
        0,
        0,
        nameDos,
        0,
        nameVUser,
        UserDocume,
        num_doc,
        phoneNumber,
        name_userId
      );
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="rounded shadow p-4 bg-light">
        <Row className="align-items-end">
          <Col sm={3}>
            <Form.Label className="form-label text-sm">
              Buscar historial por User ID o Teléfono
            </Form.Label>
            <Form.Control
              type="text"
              required
              className="input-bordered"
              placeholder="User Id o Teléfono"
              value={num_doc}
              onChange={(e) => setNumDoc(e.target.value)}
            />
          </Col>
          <Col sm={3}>
            <ApiRequests
              data={data}
              searching={searchController}
              tabla={tabla}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};
