import { Modal } from "react-bootstrap";
import { useState } from "react";

export default function Loader() {
  const [showModalC, setShowModalC] = useState(true);
  const handleCloseC = () => setShowModalC(false);
  return (
    <Modal className="d-flex align-items-center" show={showModalC} onHide={handleCloseC}>
      <Modal.Header className="border-0">
      </Modal.Header>
      <Modal.Body>
        <div className="mx-5 d-flex justify-content-evenly align-items-center">
          <div>
            <img style={{ width: '4rem', height: '4rem' }} className="spinner-border text-light fs-5" src={require("../assets/home/loader_spinner2.gif")} alt="loader"/>
          </div>
          <h2 className="fs-4 ms-4 font-semibold text-center">
         Por favor espere mientras cargan los datos...
          </h2>
        </div>
      </Modal.Body>
    </Modal>
  );
}
