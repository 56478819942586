import React from 'react'
import { useState } from "react";
import axios from 'axios';
import { Container, Row, Col, Card } from "react-bootstrap";
import { url } from '../../data/url';
import { dateCurrent } from "../../utils/DateNow";
import Cookies from "universal-cookie";
const cookie = new Cookies();

export const LoginU = () => {
    const [usuario, setUsuario] = useState("");
    const [clave, setClave] = useState("");

    const [loginStatus, setLoginStatus] = useState("");

    const submitLogin = async (e) => {
        e.preventDefault();
        e.target.reset();
        const { fechaA, horaA } = dateCurrent();

        let res = await axios.post(`${url}/login`, {
            UserA: usuario,
            PasswordU: clave,
        });
        console.log(res.data)
        let { err, user } = res.data;
        setLoginStatus(err);

        if (user) {
            cookie.set("user", user[0], { path: "/" });
            window.location.href = "/home";
            try {
                await axios.post(`${url}/createUserLogeo/logeo`, {
                    id_user: user[0].UserA,
                    nombre_user: user[0].NameU,
                    ciudad: "Bogotá",
                    cliente: "Majority",
                    campana: 'Majority',
                    fecha: fechaA,
                    hora_start: horaA,
                    hora_finish: "00:00:00",
                    estado_novedad: "Asistío",
                    estado_conexion: 1,
                });
            } catch (error) {
                console.log(error)
            }
        }
    };

    if (cookie.get("user")) {
        window.location.href = "./home";
    }

    return (
        <>
            <Container className="container-login ps-5" style={{ height: '100vh', maxWidth: '100vw', zIndex: 2 }}>
                <Row className="d-flex align-items-center " style={{ height: "100vh" }}>
                    <Col xs={5} className="position-relative" >
                        <img
                            alt=""
                            src={require("../../assets/logo.png")}
                            className="position-absolute mb-5 w-25 z-3 rounded-pill"
                            style={{ top: "-30%" }}
                        />
                        <Card className="shadow-lg bg-body-tertiary rounded-4" style={{ zIndex: 20 }}>
                            <div className="p-5 text-center">
                                <div className="text-dark mb-5">
                                    <h2>Log In</h2>
                                </div>
                                <form onSubmit={submitLogin}>
                                    <input
                                        className="mb-4 form-control"
                                        name="usuario"
                                        type="text"
                                        placeholder="user"
                                        value={usuario}
                                        onChange={(e) => setUsuario(e.target.value)}
                                    ></input>
                                    <input
                                        className="mb-4 form-control"
                                        name="clave"
                                        type="password"
                                        placeholder="password"
                                        value={clave}
                                        onChange={(e) => setClave(e.target.value)}
                                    ></input>
                                    <button className="buttons-primary form-control">
                                        Log in
                                    </button>
                                    <h3 className="text-muted fs-5 mt-2">{loginStatus}</h3>
                                </form>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
