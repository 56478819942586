exports.tipificacion = ["Venta", "Seguimiento", "No venta rechazo", "No venta no apto"];
exports.seguimiento = [
   "Preventa-Confirmar venta",
   "Preventa-Agregar dinero",
   "Preventa-Envío de dinero",
   "Preventa-Tarjeta activada",
   "Preventa-Tarjeta en espera",
   "Preventa-Tarjeta digital",
   "Preventa-Zelle",
   "Preventa-Depósito bancario",
   "Preventa-Recarga móvil",
   "Preventa-Deposito Directo",
   "Preventa-aprobó KYC - Validar",
   "Preventa-Majority pay",
   "Preventa-Multiservicio",
   "Volver a contactar - Manejando",
   "Volver a contactar - Trabajando",
   "Volver a contactar - Envío de dinero",
   "Volver a contactar - Recarga móvil",
   "Volver a contactar - WhatsApp",

];

exports.no_venta_rec = [
   "Buzón",
   "No contesta",
   "Cuelga - pendiente",
   "No aprueba KYC",
]
exports.no_venta_apto = [
   "Venta en punto físico",
   "No interesado-General",
   "No interesado-Otro banco",
   "No interesado-Mala experiencia",
   "No interesado-Desconfianza",
   "No interesado-Membresía",
   "No interesado-Ya tiene servicios",
   "No elegible-Alto riesgo",
   "No elegible-Blacklist (no llamar)",
   "No elegible-No tiene documento",
   "No elegible-Fuera de USA",
   "No elegible-Número errado",
]
exports.no_usa_app = [
   "si",
   "no"
]
exports.solo_servicio = [
   "Envió dinero",
   "Llamada - Internacional Nacional",
   "Uso tarjeta",
   "Compro paquete",
   "Majority pay",
   "Transaccion bancaria -zelle",
   "Deposito directo"
]
exports.mas_servicio = [
   "Envió dinero ",
   "Llamada - Internacional Nacional",
   "Uso tarjeta",
   "Compro paquete ",
   "Majority pay ",
   "Transaccion bancaria -zelle",
   "Deposito directo ",

]
exports.servicio_amigo = [
   "si",
   "no",
]

exports.followCategories = [
   "Venta",
   "Preventa-Confirmar venta",
   "Preventa-Agregar dinero",
   "Preventa-Envío de dinero",
   "Preventa-Tarjeta activada",
   "Preventa-Tarjeta en espera",
   "Preventa-Tarjeta digital",
   "Preventa-Zelle",
   "Preventa-Depósito bancario",
   "Preventa-Recarga móvil",
   "Preventa-Deposito Directo",
   "Preventa-aprobó KYC - Validar",
   "Preventa-Majority pay",
   "Preventa-Multiservicio",
   "Volver a contactar - Manejando",
   "Volver a contactar - Trabajando",
   "Volver a contactar - Envío de dinero",
   "Volver a contactar - Recarga móvil",
   "Volver a contactar - WhatsApp",

];

exports.campana = [
   "Inbound",
   "Outbound",
]

exports.campanaExterna = [
   "Inbound",
   "Outbound",
   "Whatsapp",
   "Superdinero",
   "Customer service",
   "Referido",
   "Wcb"
]

exports.campanaTotal = [
   "Inbound",
   "Outbound",
   "Whatsapp",
   "Superdinero",
   "Customer service",
   "Referido",
   "Wcb"
]
exports.dateGraph = ["Diario", "Semanal", "Mensual"]